import { ApplicationStatus } from "services/Interfaces";

const APPLICATION_STATUS_MAP: Record<ApplicationStatus, string> = {
  [ApplicationStatus.DRAFT]: "Draft",
  [ApplicationStatus.SUBMITTED]: "Submitted",
  [ApplicationStatus.PROCESSED]: "Processed",
  [ApplicationStatus.INPROGRESS]: "In Progress",
  [ApplicationStatus.EXPIRED]: "Expired",
  [ApplicationStatus.COMPLETED]: "Completed",
  [ApplicationStatus.CANCELLED]: "Cancelled",
};

export const applicationStatus = (status: ApplicationStatus): string => {
  return APPLICATION_STATUS_MAP[status] || "Draft";
};
