import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotifications } from "contexts/Notification";
import CSVUploader from "./CSVUploader";
import Papa from "papaparse";
import { mapCSVDataToCarrierAppointments } from "./utils";
import { CarrierAppointmentsCSVInput } from "./interfaces";
import { useMutation } from "@apollo/client";
import { Mutations } from "services/GraphQL/Mutations";
import { CarrierAppointmentsTable } from "./CarrierAppointmentsTable";
import { useFirebase } from "contexts/Firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { collection, CollectionReference } from "firebase/firestore";

type CarrierAppointmentsFirestore = CarrierAppointmentsCSVInput & {
  id: string;
};

function CarrierAppointments() {
    const { firestore } = useFirebase(); 
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [csvData, setCsvData] = useState<CarrierAppointmentsCSVInput[]>([]);
    const { notifications } = useNotifications();
    const [uploadCarrierAppointments, { loading }] = useMutation(Mutations.uploadCarrierAppointments);
    const [carrierAppointments] = useCollection(collection(firestore, "carrierAppointments") as CollectionReference<CarrierAppointmentsFirestore>, {
        snapshotListenOptions: { includeMetadataChanges: true },
    });

    const downloadSampleTemplate = () => {
        let sampleTemplateUrl = '/sample_appointment_carriers_template.csv';
        const link = document.createElement('a');
        link.href = sampleTemplateUrl;
        link.download = 'sample_appointment_carriers_template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleContinue = async () => {
        try {
            const { data } = await uploadCarrierAppointments({ variables: { csvData } });
            if (data?.uploadCarrierAppointments.success) {
                notifications.success(`${data?.uploadCarrierAppointments.count} records uploaded successfully.`);
                setSelectedFile(null);
                setCsvData([]);
            } else {
                if (data?.uploadCarrierAppointments.error) {
                    notifications.error(data?.uploadCarrierAppointments.error);
                } else {
                    notifications.error("Error uploading carrier appointments. Please try again.");
                }
            }
        } catch (error) {
            notifications.error("Error uploading carrier appointments. Please try again.");
        }    
    };
    
    useEffect(() => {
      if (selectedFile) {
        // Check if the file format is CSV
        if (!selectedFile.name.toLowerCase().endsWith(".csv")) {
          notifications.error("Invalid file format. Please upload a CSV file.");
          setSelectedFile(null);
          return;
        }
        const maxSizeInBytes = 10 * 1024 * 1024; // 10 MB
        if (selectedFile.size > maxSizeInBytes) {
          notifications.error("File size exceeds the limit. Please upload a file smaller than 10 MB.");
          setSelectedFile(null);
          return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
          const content = e.target && (e.target.result as string);
          Papa.parse(content as any, {
            skipEmptyLines: "greedy",
            complete: (result: any) => {
              const csvRecords = result.data;

              if (!csvRecords || csvRecords.length === 0) {
                notifications.error("No records found in the CSV file.");
                setSelectedFile(null);
                return;
              }

              const mappedData = mapCSVDataToCarrierAppointments(csvRecords);

              setCsvData(mappedData);
            },
            error: (error: any) => {
              notifications.error("Error parsing CSV file. Please check the file format.");
              setSelectedFile(null);
            },
          });

        };

        reader.readAsText(selectedFile);
      }
    }, [selectedFile, notifications]);

    if (loading) {
      return <LinearProgress />;
    }

    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: "12px" }}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="space-between">
            <Typography fontWeight="600" variant="h4">
              Upload Carrier Appointments
            </Typography>
            <Typography style={{ marginTop: "12px" }} color="gray.main" fontSize="small">
              Please upload a CSV file to proceed.
            </Typography>
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="black"
              startIcon={<DescriptionOutlinedIcon />}
              onClick={downloadSampleTemplate}
            >
              Download Sample Template
            </Button>
          </Box>
        </Box>
        <CSVUploader setSelectedFile={setSelectedFile} selectedFile={selectedFile} onContinue={handleContinue} />
        <CarrierAppointmentsTable
          rows={
            carrierAppointments?.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })) || []
          }
        />
      </>
    );
}

export default CarrierAppointments;