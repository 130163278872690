import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { CarrierAppointmentsCSVInput } from "./interfaces";

const columns: GridColDef[] = [
  { field: 'lastName', headerName: 'Last Name', width: 100 },
  { field: 'firstName', headerName: 'First Name', width: 100 },
  { field: 'NPN', headerName: 'NPN', width: 100 },
  { field: 'homeState', headerName: 'Home State', width: 100 },
  { field: 'producerStatus', headerName: 'Producer Status', width: 100 },
  { field: 'state', headerName: 'State', width: 100 },
  { field: 'carrier', headerName: 'Carrier', width: 100 },
  { field: 'NAICCode', headerName: 'NAIC Code', width: 100 },
  { field: 'LOA', headerName: 'LOA', width: 100 },
  { field: 'appointmentStatus', headerName: 'Appointment Status', width: 100 },
  { field: 'appointmentEffectiveDate', headerName: 'Appointment Effective Date', width: 100 },
  { field: 'appointmentExpirationDate', headerName: 'Appointment Expiration Date', width: 100 },
  { field: 'LOAStatus', headerName: 'LOA Status', width: 100 },
  { field: 'OrgName', headerName: 'Org Name', width: 100 },
];

export function CarrierAppointmentsTable({ rows }: { rows: CarrierAppointmentsCSVInput[] }) {
  return (
    <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid columns={columns} rows={rows} />
    </Box>
  );
}
