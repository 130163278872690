import { Card, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import EnrollmentInformation from "./EnrollmentInformation";
import EnrollNow from "./EnrollNow";
import HumanHandoff from "./HumanHandoff";
import PreferencesTemplate from "./PreferencesTemplate";
import Recommendations from "./Recommendations";
import UploadResume from "./UploadResume";
import { Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

function DynamicTemplate({
    template,
    data,
    sendPrompt,
    userData
}: {
    template: string,
    data: any,
    sendPrompt: (message: string) => void,
    userData: any
}) {
    const theme = useTheme();

    if (template === "showRecommendedPlans") {
        return (
            <Box sx={{
                maxWidth: { xs: "100%", sm: "75%" },
                marginRight: "auto"
            }}>
                <Recommendations
                    sendPrompt={sendPrompt}
                    conversationId={userData?.get("conversationId")}
                />
            </Box>
        )
    } else if (template === "resumeViewer") {
        return (
            <Box sx={{
                maxWidth: { xs: "100%", sm: "75%" },
                marginRight: "auto"
            }}>
                <Card sx={{ p: 2, backgroundColor: "#DDD" }}>
                    <Markdown remarkPlugins={[remarkGfm]}>
                        {data.content}
                    </Markdown>
                </Card>
            </Box>
        )
    } else if (template === "uploadResume") {
        return (
            <Box>
                <UploadResume />
            </Box>
        )
    } else if (template === "enrollInSelectedPlan") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                borderRadius: "17px 17px 2px 17px",
            }}>
                <EnrollNow />
            </Box>
        );
    } else if (template === "showEnrollmentInformation" || template === "updateEnrollmentInformation") {
        return (
            <EnrollmentInformation sendPrompt={sendPrompt} />
        );
    } else if (template === "updatePreferences") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                width: "100%",
                borderRadius: "17px 17px 2px 17px",
            }}>
                <PreferencesTemplate sendPrompt={sendPrompt} />
            </Box>
        );
    } else if (template === "lineChart") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                width: "100%",
                borderRadius: "17px 17px 2px 17px",
            }}>
                <Typography>{data.name}</Typography>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart width={600} height={400} data={data.data}>
                        <XAxis dataKey="xAxisLabel" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="line1" name={data.lineNames[0]} stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
                        {data.lineNames.slice(1).map((lineName: string, index: number) => (
                            <Line key={lineName} type="monotone" dataKey={`line${index + 2}`} name={lineName} stroke={theme.palette.secondary.main} activeDot={{ r: 8 }} />
                        ))}
                    </LineChart >
                </ResponsiveContainer>
            </Box>
        );
    } else if (template === "human") {
        return (
            <Box sx={{
                display: "block",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
                backgroundColor: "beige.main",
                p: 4,
                maxWidth: { xs: "100%", sm: "75%" },
                width: "100%",
                borderRadius: "17px 17px 2px 17px",
            }}>
                <HumanHandoff sendPrompt={sendPrompt} />
            </Box>
        );
    }
    return (
        <Box sx={{
            maxWidth: { xs: "100%", sm: "75%" },
            marginRight: "auto"
        }}>
            <Typography>Missing template: {template}</Typography>
        </Box>
    );
}
export default DynamicTemplate;