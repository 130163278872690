import { DocumentData, SnapshotOptions, FirestoreDataConverter, QueryDocumentSnapshot } from "firebase/firestore";
import moment from "moment";
import { ServiceMarketPlaceType } from "services/Interfaces";


export const ServiceMarketPlaceConverter: FirestoreDataConverter<ServiceMarketPlaceType> = {
  toFirestore(service: ServiceMarketPlaceType): DocumentData {
    return {
      ...service,
      createdAt: service.createdAt ? service.createdAt.toDate() : moment().toDate()
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ServiceMarketPlaceType {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      serviceName: data.serviceName,
      serviceCategory: data.serviceCategory,
      serviceProvider: data.serviceProvider,
      tileHeader: data.tileHeader,
      tileBodyText: data.tileBodyText,
      CTAButton: data.CTAButton,
      CTAButtonText: data.CTAButtonText,
      CTAButtonURL: data.CTAButtonURL,
      redeemCode: data.redeemCode,
      phoneButton: data.phoneButton,
      phoneNumber: data.phoneNumber,
      fontColor: data.fontColor,
      tilesVisibility: data.tilesVisibility,
      isActive: data.isActive,
      headerPhoto: data?.headerPhoto,
      logoFile: data?.logoFile,
    };
  },
};