// components/CSVUploader/index.tsx
import { Box, Typography, Divider, Button, DialogActions } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useRef } from "react";

interface CSVUploaderProps {
  setSelectedFile: (file: File | null) => void;
  selectedFile: File | null;
  downloadTemplate?: () => void;
  onContinue?: () => void;
}

function CSVUploader({ setSelectedFile, selectedFile, downloadTemplate, onContinue }: CSVUploaderProps) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleTextClick = () => {
    const fileInput = document.getElementById("file-input");
    if (fileInput) {
      fileInput.click();
    }
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);

    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Box sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "10px" }} marginY="16px">
      {!selectedFile ? (
        <>
          <Box>
            <input
              accept=".csv"
              type="file"
              id="file-input"
              onChange={handleFileChange}
              style={{ display: "none" }}
          ref={fileInputRef}
        />
        <Box
          border="2px dashed #aaa"
          borderRadius="10px"
          padding="24px"
          textAlign="center"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={handleTextClick}
          sx={{ cursor: "pointer" }}
        >
          <CloudUploadOutlinedIcon fontSize="medium" />
          <br />
          <Typography color="gray.main" style={{ cursor: "pointer" }} margin={2}>
            Click or drag file to this area to upload
          </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display={"flex"}
            borderRadius="10px"
            justifyContent={"space-between"}
            style={{ border: "1px solid gray", marginTop: "12px", padding: 5 }}
            sx={{
              backgroundColor: "#F8FFF7",
            }}
          >
            <>
              <CheckCircleOutlineOutlinedIcon sx={{ color: "#177B06", margin: 1 }} />
              <Typography
                  sx={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    margin: 1,
                  }}
                >
                  {selectedFile.name}
                </Typography>
                <DeleteOutlineOutlinedIcon sx={{ margin: 1 }} onClick={removeSelectedFile} />
            </>
          </Box>
        </>
      )}
      {selectedFile &&
        <>
          <Divider style={{ marginTop: "12px" }} />

        <DialogActions sx={{ justifyContent: "space-between", margin: "24px", padding: 0 }}>
          <Button
            onClick={() => {
              removeSelectedFile();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={onContinue} variant="contained" color="primary">
          Continue
            </Button>
          </DialogActions>
        </>
}
      {/* <Typography style={{ margin: "16px 0" }} color="gray.main" fontSize="small">
        If you do not have a file, you can download a template below:{" "}
      </Typography>
      <Box>
        <Button variant="outlined" color="black" startIcon={<DescriptionOutlinedIcon />} onClick={downloadTemplate}>
          Download Sample Template
        </Button>
      </Box> */}
    </Box>
  );
}

export default CSVUploader;
