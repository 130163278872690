/** @jsxImportSource @emotion/react */

import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { useAuth } from "./contexts/User";
import { Login, Dashboard, Logout, NotFoundPage, InsurancePlans } from "./pages";
import React, { Suspense, lazy, useEffect, useState } from "react";
import TransitionAssistant from "pages/TransitionAssistant";
import { Typography } from "@mui/material";
import MyAccount from "pages/MyAccount/MyAccount";
import RecommendedPlans from "pages/Account/RecommendedPlans";
import PasswordReset from "pages/PasswordReset";
import { Helmet } from "react-helmet-async";
import { useCompany } from "contexts/Company";
import { CompanyUserRoles } from "services/Interfaces";

import EmployeeExitJamie from "pages/Exit/EmployeeExitJamie";
import EmployeeExitDetails from "pages/Exit/EmployeeExitDetails";
import WhenBenefitPage from "pages/Account/WhenBenefit";
import JamiePrototype from "pages/Admin/JamiePrototype";
import SuperJamie from "pages/Admin/SuperJamie";
import JamieAdmin from "pages/Admin/JamieAdmin";
import AuthActionPage from "components/User/ActionHandler";
import { useRemoteConfig } from "contexts/RemoteConfig";
import Services from "pages/Account/Services";
import SSO from "pages/SSO";
import ZendeskTicket from "pages/Admin/ZendeskTicket";
import mixpanel from 'mixpanel-browser';
import JamieUnified from "pages/JamieUnified";
import ConversationDetails from "pages/Admin/ConversationDetails";
import DataPipelines from "pages/Admin/DataPipelines";
import CarrierAppointments from "pages/Admin/CarrierAppointments";

const InsurancePlansOld = lazy(() => import("pages/Account/InsurancePlansOld"));
const ClaimPage = lazy(() => import("pages/Admin/Reimbursement"));
const DesignSystem = lazy(() => import("pages/Admin/DesignSystem"));
const ReimbursementsPage = lazy(() => import("pages/Admin/Reimbursements"));
const ApplicationsPage = lazy(() => import("pages/Admin/Applications"));
const ServiceMarketPlace = lazy(() => import("pages/Admin/ServiceMarketPlace"));
const Opportunities = lazy(() => import("pages/Admin/Opportunities"));
const Opportunity = lazy(() => import("pages/Admin/Opportunity"));
const Quote = lazy(() => import("pages/Admin/Quote"));
const Company = lazy(() => import("pages/Admin/Company"));
const Companies = lazy(() => import("pages/Admin/Companies"));
const AdminDashboard = lazy(() => import("pages/Admin/AdminDashboard"));
const Setup = lazy(() => import("pages/Company/Setup"));
const Employee = lazy(() => import("pages/Company/Employee"));
const Cases = lazy(() => import("pages/Company/Cases"));
const AdminCases = lazy(() => import("pages/Admin/Cases"));
const AdminCase = lazy(() => import("pages/Admin/Case"));
const AdminUser = lazy(() => import("pages/Admin/User"));
const AdminUsers = lazy(() => import("pages/Admin/AdminUsers"));
const Users = lazy(() => import("pages/Admin/Users"));
const CasePage = lazy(() => import("pages/Company/Case"));
const ChecklistTemplate = lazy(() => import("pages/Company/Checklist"));
const Checklists = lazy(() => import("pages/Company/Checklists"));
const TasksPage = lazy(() => import("pages/Company/Tasks"));
const BenefitMap = lazy(() => import("pages/Company/Settings/BenefitMap"));
const System = lazy(() => import("pages/Company/Settings/System"));
const Employees = lazy(() => import("pages/Company/Employees"));
const DocumentTemplates = lazy(() => import("pages/Company/DocumentTemplates"));
const DocumentTemplate = lazy(() => import("pages/Company/DocumentTemplate"));
const SeverancePackages = lazy(() => import("pages/Company/SeverancePackages"));
const SeverancePackagePage = lazy(() => import("pages/Company/SeverancePackage"));
const CompanyConnect = lazy(() => import("pages/Company/Connect"));
const CompanyUsers = lazy(() => import("./pages/Company/Settings/Users"));
const CompanyDashboard = lazy(() => import("pages/Company/CompanyDashboard"));
const CompanyLandingPage = lazy(() => import("pages/LandingPages/CompanyLandingPage/CompanyLandingPage"));
const ISolvedLandingPage = lazy(() => import("pages/LandingPages/ISolvedLandingPage/ISolvedLandingPage"));
const UniversityLandingPage = lazy(() => import("pages/LandingPages/UniversityLandingPage/UniversityLandingPage"));
const ActiveEmployeeLandingPage = lazy(() => import("pages/LandingPages/ActiveEmployeeLandingPage/ActiveEmployeeLandingPage"));
const UniqueCaseLandingPage = lazy(() => import("pages/LandingPages/UniqueCaseLandingPage/UniqueCaseLandingPage"));
const CreateApplication = lazy(() => import("pages/Application/CreateApplication"));
const HrisIntegrations = lazy(() => import("pages/Admin/HrisIntegrations"));

const Loading = () => <Typography>Loading...</Typography>;

function Routing() {
  const { user } = useAuth();
  const insurancePlansVersion = useRemoteConfig("insurancePlansVersion").asString()
  const UnifiedJamie = useRemoteConfig("unifiedJamie").asBoolean();
  const whenEnrollmentFlag = useRemoteConfig("whenEnrollment").asBoolean() || false;
  useEffect(() => {
    if (user?.uid) {
      mixpanel.identify(user?.uid);
    }
    mixpanel.track_pageview();
  })
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/sso" element={<SSO />} />
      <Route path="/__/auth/action" element={<AuthActionPage />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="connect">
        <Route path="company/:connection" element={<CompanyConnect />} />
      </Route>
      <Route path="exit">
        <Route path=":companyId/:exitCode" element={<UniqueCaseLandingPage />} />
        <Route path=":companyId" element={<CompanyLandingPage />} />
      </Route>
      <Route path="/landing" element={<RequireAuth role="anon"><Outlet /></RequireAuth>}>
        <Route path="isolved" element={<ISolvedLandingPage />} />
      </Route>
      <Route path="students">
        <Route path=":companyId" element={<UniversityLandingPage />} />
      </Route>
      <Route path="employees">
        <Route path=":companyId/:employeeId?" element={<ActiveEmployeeLandingPage />} />
      </Route>
      <Route path="/" element={<StageBlock><Outlet /></StageBlock>}>
        <Route path="transition-assistant" element={<Navigate to="/jamie" />}></Route>
        <Route path="logout" element={<Logout />} />
        <Route path="setup" element={<Setup />}></Route>
        <Route path="jamie" element={<><RequireAuth role="anon"><Outlet /></RequireAuth></>}>
          <Route
            path=""
            element={
              <>
                <Helmet>
                  <title>Jamie, your Health Insurance Advocate</title>
                  <meta name="description" content="Jamie is designed to help you find the ideal health insurance policy through a user-friendly, conversational interface, providing personalized recommendations and expert guidance along the way." />
                </Helmet>
                {UnifiedJamie ? <JamieUnified /> : <TransitionAssistant />}
              </>
            }
          />
          <Route path="recommended-plans" element={<RecommendedPlans />}></Route>
        </Route>
        <Route path="exit">
          <Route path="jamie" element={UnifiedJamie ? <JamieUnified /> : <EmployeeExitJamie />} />
          <Route path="details" element={<EmployeeExitDetails />} />
        </Route>
        <Route path="company" element={<RequireAuth companyRole={Object.values(CompanyUserRoles)}><Suspense fallback={<Loading />}><Outlet /></Suspense></RequireAuth>}>
          <Route path="employees" element={<Employees />} />
          <Route path="employees/:employeeId" element={<Employee />} />
          <Route path="cases/:caseId" element={<CasePage />} />
          <Route path="cases" element={<Cases />} />
          <Route path="tasks" element={<TasksPage />} />
          <Route path="dashboard" element={<CompanyDashboard />} />
          <Route path="settings">
            <Route path="users" element={<CompanyUsers />} />
            <Route path="system" element={<System />} />
            <Route path="benefits" element={<BenefitMap />} />
            <Route path="documents" element={<DocumentTemplates />} />
            <Route path="documents/:documentId" element={<DocumentTemplate />} />
            <Route path="severance-packages" element={<SeverancePackages />} />
            <Route path="severance-packages/:severancePackageId" element={<SeverancePackagePage />} />
            <Route path="checklists/:checklistId" element={<ChecklistTemplate />} />
            <Route path="checklists" element={<Checklists />} />
          </Route>
          <Route path="" element={<CompanyDashboard />} />
        </Route>
        <Route path="account" element={<RequireAuth><Outlet /></RequireAuth>}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="My-Account" element={<MyAccount />} />
          <Route path="when-benefit" element={<WhenBenefitPage />} />
          <Route path="insurance-plans" element={insurancePlansVersion === "v2" ? <InsurancePlans /> : <InsurancePlansOld />}></Route>
          <Route path="services" element={<Services />} />
          <Route path="" element={<Navigate to="/account/dashboard" />} />
          <Route path="applications/:applicationId" element={<CreateApplication />}></Route>
        </Route>
        <Route path="admin" element={<RequireAuth role="admin"><Suspense fallback={<Loading />}><Outlet /></Suspense></RequireAuth>}>
          <Route path="" element={<AdminDashboard />} />
          <Route path="applications/:applicationId" element={<CreateApplication />}></Route>
          <Route path="zendesk/ticket" element={<ZendeskTicket />} />
          <Route path="adminusers" element={<AdminUsers />} />
          <Route path="carrier-appointments" element={<CarrierAppointments />} />
          <Route path="cases" element={<AdminCases />} />
          <Route path="cases/:hash" element={<AdminCase />} />
          <Route path="companies" element={<Companies />} />
          <Route path="companies/:companyId" element={<Company />} />
          <Route path="opportunities/:opportunityId" element={<Opportunity />} />
          <Route path="opportunities/:opportunityId/quotes/:quoteId" element={<Quote />} />
          <Route path="opportunities" element={<Opportunities />} />
          <Route path="reimbursements" element={<ReimbursementsPage />} />
          <Route path="applications" element={whenEnrollmentFlag ? <ApplicationsPage /> : <Navigate to="/admin" />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:hash" element={<AdminUser />} />
          <Route path="users/:userId/applications/:applicationId" element={whenEnrollmentFlag ? <CreateApplication /> : <Navigate to="/admin" />} />
          <Route path="reimbursements/:userId/:whenId" element={<ClaimPage />} />
          <Route path="serviceMarketPlace" element={<ServiceMarketPlace />} />
          <Route path="integrations" element={<HrisIntegrations />} />
          <Route path="design" element={<DesignSystem />} />
          <Route path="data-pipelines" element={<DataPipelines />} />
          <Route path="jamie" element={<JamiePrototype />} />
          <Route path="super-jamie" element={<SuperJamie />} />
          <Route path="jadmin/:conversationId" element={<ConversationDetails />} />
          <Route path="jadmin/*" element={<JamieAdmin />} />


        </Route>
        <Route path="" element={<Navigate to="/account/dashboard" />} />
      </Route>
      <Route path="" element={<Typography>Loading</Typography>} />
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
export default Routing;

/**
 * A component that requires authentication and authorization to access its children.
 * @param children The child components to render if the user is authenticated and authorized.
 * @param role The user role required to access the child components.
 * @param companyRole The company role required to access the child components.
 * @returns The child components if the user is authenticated and authorized, or a redirect to the login page otherwise.
 */
function RequireAuth({ children, role, companyRole }: { children: any, role?: string, companyRole?: string[] }) {
  const { companyRoles } = useCompany();
  console.log("require auth", companyRole, companyRoles)

  const { loginAnonymously, user, authed, userClaims } = useAuth();
  const [renderAnon, setRenderAnon] = useState<boolean>(false);
  const location = useLocation();
  if (role === "") {
    console.log("Cannot use an empty role in Route", location.pathname);
    return <Navigate to="/404" replace />;
  } else if (role === "admin" && (userClaims.admin === true || userClaims?.roles?.admin?.viewer === true || userClaims?.roles?.admin?.editor === true)) {
    console.log("has admin role")
    return children;
  } else if (role === "admin" && !(userClaims.admin === true || userClaims?.roles?.admin?.viewer === true || userClaims?.roles?.admin?.editor === true)) {
    console.log("company dashboard")
    return <Navigate to="/" replace />;
  } else if (role === "companyAdmin" && userClaims.companyAdmin === true) {
    console.log("has company admin role")
    return children;
  } else if (companyRole && companyRoles &&
    companyRole.some((role) => companyRoles.includes(role) === true)) {
    console.log("has company role")
    return authed ? children : <Navigate to="/login" replace />;
  } else if (!role && !companyRole && authed === true) {
    console.log("RequireAuth, authed")
    return children;
  } else if (role === "anon") {
    if (authed || renderAnon || user?.isAnonymous) {
      console.log("RequireAuth, anon")
      return children;
    } else {
      console.log("RequireAuth, loginAnonymously")
      loginAnonymously().then(() => {
        console.log("anonymously logged in")
        setRenderAnon(true);
      });
      return (
        <Typography>Loading</Typography>
      )
    }
  } else if (role !== undefined || companyRole !== undefined) {
    console.log("404 not found with role", location.pathname);
    return <Navigate to="/404" replace />;
  } else {
    console.log("redirect to login")
    return <Navigate to="/login" replace state={{ path: `${location.pathname}${location.search}`, search: location.search }} />;
  }
}

/**
 * Block access to Stage if the user is not authenticated.
 * @param children The child components to render if the user is authenticated
 * @returns the child components if the user is authenticated, or a redirect to the login page otherwise.
 */
function StageBlock({ children }: { children: any }) {
  const { authed } = useAuth();
  const location = useLocation();
  const loginRestriction = useRemoteConfig('loginRestriction').asBoolean() || false;
  if (loginRestriction) {
    return authed === true
      ? children
      : <Navigate to="/login" replace state={{ path: location.pathname }} />;
  } else {
    return children;
  }
}