/** @jsxImportSource @emotion/react */
import {
  Typography,
  Box,
  Button,
  Dialog,
  OutlinedInput,
  InputLabel,
  FormControl,
  Switch,
} from "@mui/material";
import { doc } from "@firebase/firestore";
import { AdminCaseActivityConverter, CaseConverter } from "services/Firestore";
import {
  AdminCaseActivity,
  Case,
  CaseActivityTypes,
  SharedStatus,
} from "services/Interfaces";
import { addDoc, collection, setDoc } from "firebase/firestore";
import moment, { Moment } from "moment";
import { Warning } from "@mui/icons-material";
import { useAuth } from "contexts/User";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useNotifications } from "contexts/Notification";
import { useFirebase } from "contexts/Firebase";

const AdminSharedWithEmployeeDialog = ({
  caseId,
  toggleSharedDialog,
  sharedDialogOpen,
  theCase,
  isCaseShared,
}: {
  caseId?: string;
  sharedDialogOpen: boolean;
  theCase: any;
  toggleSharedDialog: () => void;
  isCaseShared: boolean;
}) => {
  const { firestore } = useFirebase();
  const { userData } = useAuth();
  const [employeeEmail, setEmployeeEmail] = useState<string>(
    theCase.data().employee?.email
  );
  const employeeMobile = theCase.data().employee?.phone;
  const employeeLastName = theCase.data().employee?.lastName;
  const employeeDob = theCase.data().employee?.dateOfBirth
    ? moment(theCase.data().employee?.dateOfBirth, "MM/DD/YYYY")
    : moment();
  const employeeZipCode = theCase.data().employee?.home?.zip;
  const [scheduleSharing, setScheduleSharing] = useState(false);
  const [scheduleSharingDate, setScheduleSharingDate] = useState<Moment>();
  const [validationObject, setValidationObject] = useState<any>({});
  const { notifications } = useNotifications(); // set notification for success, error, info
  const todayDate = new Date().toLocaleDateString("en-US");

  const SHARE_CASE_COPY =
    "Sharing this case will invite the employee to create their account, authorizes When to communicate with the employee, and makes their When health insurance reimbursement benefit visible (if applicable). Are you sure you want to share this case now?";
  const RESHARE_CASE_COPY =
    "Re-sharing the case will restart email and SMS campaigns to the user. Do you want to re-share this case?";

  // confirm exit
  const handleExit = async () => {
    if (!userData || !theCase) return;
    if (scheduleSharing && !scheduleSharingDate) {
      setValidationObject({
        ...validationObject,
        scheduleSharingDate:
          "Please provide a date for Schedule Sharing or turn off Schedule Sharing.",
      });
      return;
    }
    if (!employeeZipCode) {
      setValidationObject({
        ...validationObject,
        zipCode: "Please enter valid zip code",
      });
      return;
    }
    if (!employeeLastName) {
      setValidationObject({
        ...validationObject,
        lastName: "Please enter last name",
      });
      return;
    }
    if (!employeeDob) {
      setValidationObject({
        ...validationObject,
        date: "Please enter date of birth",
      });
      return;
    }
    if (
      validationObject["dob"] ||
      validationObject["date"] ||
      validationObject["zipCode"] ||
      validationObject["lastName"] ||
      validationObject["scheduleSharingDate"]
    )
      return;
    else {
      try {
        let docData: Partial<Case> = {
          employee: {
            email: employeeEmail,
            phone: employeeMobile,
            dateOfBirth: moment(employeeDob).format("MM/DD/YYYY"),
            lastName: employeeLastName,
            home: {
              zip: employeeZipCode,
            },
          } as any,
          contact: {
            id: theCase.data().contact?.id,
            firstName: theCase.data().employee?.firstName,
            firstName_lower: theCase.data().employee?.firstName.toLowerCase(),
            lastName: employeeLastName,
            lastName_lower: employeeLastName.toLowerCase(),
            email: employeeEmail,
            email_lower: employeeEmail.toLowerCase(),
            phone: employeeMobile,
            dateOfBirth: moment(employeeDob).format("MM/DD/YYYY"),
            address: {
              street1: theCase.data().contact?.address.street1,
              street2: theCase.data().contact?.address.street2,
              city: theCase.data().contact?.address.city,
              state: theCase.data().contact?.address.state,
              zip: employeeZipCode,
              country: theCase.data().contact?.address.country || "US",
            }
          },
        };

        if (isCaseShared) {
          // Reset klaviyoCaseShared flag if the case has already been shared
          // Setting flag to false will re-share the case before setting flag back to true
          docData.klaviyoCaseShared = false;
        } else if (scheduleSharing && scheduleSharingDate) {
          // Handle scheduled sharing for unshared cases
          docData.shared = scheduleSharingDate
            .toDate()
            .toLocaleDateString("en-US");
        } else {
          // Immediately share the case if not scheduling
          docData.shared = todayDate;
          docData.sharedStatus = SharedStatus.SUCCESS;
        }

        await setDoc(
          doc(
            firestore,
            `companies/${theCase.data().companyId}/cases/${caseId}`
          ).withConverter(CaseConverter),
          docData,
          { merge: true }
        );

        await addDoc<AdminCaseActivity>(
          collection(
            firestore,
            "companies",
            theCase.data().companyId,
            "cases",
            theCase.id!,
            "activity"
          ).withConverter(AdminCaseActivityConverter),
          {
            activity: CaseActivityTypes.COMMENT,
            type: "case",
            userId: userData.id,
            caseId: theCase.id!,
            companyId: theCase.data().companyId,
            displayName: "Case Shared",
            message: "Case Shared With Employee",
            timestamp: moment(),
            accessedBy: userData.id,
          }
        );
        if (scheduleSharing) {
          notifications.success("Case scheduled successfully");
        } else {
          notifications.success("Case shared successfully");
        }
      } catch (e) {
        notifications.error("An error occurred while sharing case");
        console.log("unable to share case", e);
      }
      toggleSharedDialog();
      setScheduleSharing(false);
    }
  };

  const handleToggle = (
    setter: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setter((prev) => !prev);
  };

  useEffect(() => {
    if (theCase) {
      // if email has been changed in the edit modal
      setEmployeeEmail(theCase.data().employee.email);
    }
  }, [theCase, sharedDialogOpen]);

  return (
    <Dialog open={sharedDialogOpen} onClose={toggleSharedDialog}>
      <Box
        sx={{
          width: 500,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h2">
          {isCaseShared
            ? "Re-share case with employee"
            : "Share case with employee"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Warning color="warning" />
          <Typography sx={{ marginBottom: 1 }}>
            {isCaseShared ? RESHARE_CASE_COPY : SHARE_CASE_COPY}
          </Typography>
        </Box>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-email">Contact email *</InputLabel>
          <OutlinedInput
            type="email"
            label="Personal email"
            id="outlined-email"
            value={employeeEmail}
            disabled
            required
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-mobile">Phone</InputLabel>
          <OutlinedInput
            type="text"
            label="mobile"
            id="outlined-mobile"
            disabled
            value={employeeMobile}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-mobile">Last Name</InputLabel>
          <OutlinedInput
            type="text"
            label="Last Name"
            id="outlined-mobile"
            disabled
            value={employeeLastName}
          />
          {validationObject["lastName"] && (
            <Typography component={"div"} color={"error"} variant="caption">
              {validationObject["lastName"]}
            </Typography>
          )}
        </FormControl>
        <FormControl>
          <DatePicker
            label={"Date of Birth"}
            value={employeeDob}
            maxDate={moment()}
            disabled
            onError={(newError) => {
              setValidationObject({
                ...validationObject,
                dob: newError
                  ? "Please provide a date with the following format MM/DD/YYYY"
                  : "",
              });
            }}
          ></DatePicker>
          {(validationObject["dob"] || validationObject["date"]) && (
            <Typography component={"div"} color={"error"} variant="caption">
              {validationObject["dob"]
                ? validationObject["dob"]
                : validationObject["date"]}
            </Typography>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-mobile">Zip Code</InputLabel>
          <OutlinedInput
            type="text"
            label="Zip Code"
            id="outlined-mobile"
            disabled
            value={employeeZipCode || ""}
            error={Boolean(validationObject["zipCode"])}
          />
          {validationObject["zipCode"] && (
            <Typography component={"div"} color={"error"} variant="caption">
              {validationObject["zipCode"]}
            </Typography>
          )}
        </FormControl>
        {!isCaseShared && (
          <FormControl>
            <Box display={"flex"} gap={2}>
              <Typography variant="h6" color="primary">
                Schedule Sharing
                <Switch
                  checked={scheduleSharing}
                  onChange={() => handleToggle(setScheduleSharing)}
                />
              </Typography>
            </Box>
          </FormControl>
        )}
        {scheduleSharing && (
          <FormControl>
            <DatePicker
              label={"Date of Schedule Sharing"}
              value={scheduleSharingDate || null}
              minDate={moment()}
              onError={(newError) => {
                setValidationObject({
                  ...validationObject,
                  scheduleSharingDate: newError
                    ? "Please provide a date with the following format MM/DD/YYYY"
                    : "",
                });
              }}
              onChange={(date: any) => {
                setScheduleSharingDate(date);
                setValidationObject({
                  ...validationObject,
                  scheduleSharingDate: "",
                });
              }}
            ></DatePicker>
            {validationObject["scheduleSharingDate"] && (
              <Typography component={"div"} color={"error"} variant="caption">
                {validationObject["scheduleSharingDate"]}
              </Typography>
            )}
          </FormControl>
        )}
        <Button variant="contained" color="primary" onClick={handleExit}>
          Yes
        </Button>
        <Button onClick={toggleSharedDialog}>Cancel</Button>
      </Box>
    </Dialog>
  );
};
export default AdminSharedWithEmployeeDialog;
