/** @jsxImportSource @emotion/react */
import {
  Typography,
  Box,
  Button,
  Dialog,
  FormControl,
  TextField,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { doc } from "@firebase/firestore";
import { AdminCaseActivityConverter, CaseConverter } from "services/Firestore";
import {
  AdminCaseActivity,
  CaseActivityChange,
  CaseActivityTypes,
  CaseReason,
  CaseReasonText,
  TerminationTypes,
  VoluntaryReasons,
} from "services/Interfaces";
import { useEffect, useReducer, useState } from "react";
import { addDoc, collection, setDoc } from "firebase/firestore";
import moment, { Moment } from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { useAuth } from "contexts/User";
import { useNotifications } from "contexts/Notification";
import { useFirebase } from "contexts/Firebase";
import { validateEmailFormat, validateZipCode } from "services/validation";

function addActivityChange(
  changes: CaseActivityChange[],
  field: string,
  oldValue: any,
  newValue: any
) {
  if (oldValue !== newValue) {
    changes.push({
      field,
      oldValue,
      newValue,
    });
  }
}

interface FormState {
  lastDay: Moment | null;
  terminationDate: Moment | null;
  phone: string;
  email: string;
  terminationType: TerminationTypes.VOLUNTARY | TerminationTypes.INVOLUNTARY;
  reason: CaseReason | string;
  dateOfBirth: Moment | null;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

type FormAction =
  | { type: "SET_LAST_DAY"; payload: Moment | null }
  | { type: "SET_TERMINATION_DATE"; payload: Moment | null }
  | { type: "SET_PHONE"; payload: string }
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_TERMINATION_TYPE"; payload: TerminationTypes.VOLUNTARY | TerminationTypes.INVOLUNTARY }
  | { type: "SET_REASON"; payload: string }
  | { type: "SET_DATE_OF_BIRTH"; payload: Moment | null }
  | { type: "SET_STREET1"; payload: string }
  | { type: "SET_STREET2"; payload: string }
  | { type: "SET_CITY"; payload: string }
  | { type: "SET_STATE"; payload: string }
  | { type: "SET_ZIP"; payload: string };

const initialState: FormState = {
  lastDay: null,
  terminationDate: null,
  phone: "",
  email: "",
  terminationType: TerminationTypes.VOLUNTARY,
  reason: "",
  dateOfBirth: null,
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
};

function formReducer(state: FormState, action: FormAction): FormState {
  switch (action.type) {
    case "SET_LAST_DAY":
      return { ...state, lastDay: action.payload };
    case "SET_TERMINATION_DATE":
      return { ...state, terminationDate: action.payload };
    case "SET_PHONE":
      return { ...state, phone: action.payload };
    case "SET_EMAIL":
      return { ...state, email: action.payload };
    case "SET_TERMINATION_TYPE":
      return { ...state, terminationType: action.payload };
    case "SET_REASON":
      return { ...state, reason: action.payload };
    case "SET_DATE_OF_BIRTH":
      return { ...state, dateOfBirth: action.payload };
    case "SET_STREET1":
      return { ...state, street1: action.payload };
    case "SET_STREET2":
      return { ...state, street2: action.payload };
    case "SET_CITY":
      return { ...state, city: action.payload };
    case "SET_STATE":
      return { ...state, state: action.payload };
    case "SET_ZIP":
      return { ...state, zip: action.payload };
    default:
      return state;
  }
}

const AdminCaseEditDialog = ({
  editCaseOpen,
  setEditCaseOpen,
  theCase,
  caseId,
}: {
  editCaseOpen: boolean;
  setEditCaseOpen: Function;
  theCase?: any;
  caseId?: any;
}) => {
  const { firestore } = useFirebase();
  const [error, setError] = useState<string>("");
  const { userData } = useAuth();
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const [invalidTerminationDate, setInvalidTerminationDate] = useState("");
  const [invalidLastDate, setInvalidLastDate] = useState("");
  const { notifications } = useNotifications();

  // when the case loads set the values
  useEffect(() => {
    if (theCase) {
      dispatch({
        type: "SET_TERMINATION_DATE",
        payload: moment(theCase.data()?.terminationDate, "MM/DD/YYYY"),
      });
      dispatch({
        type: "SET_LAST_DAY",
        payload: moment(theCase.data()?.lastDay, "MM/DD/YYYY"),
      });
      dispatch({
        type: "SET_PHONE",
        payload: theCase.data()?.employee.phone || "",
      });
      dispatch({
        type: "SET_EMAIL",
        payload: theCase.data()?.employee.email || "",
      });
      dispatch({
        type: "SET_TERMINATION_TYPE",
        payload: theCase.data()?.terminationType || "VOLUNTARY",
      });
      dispatch({ type: "SET_REASON", payload: theCase.data()?.reason || "" });
      dispatch({
        type: "SET_DATE_OF_BIRTH",
        payload: theCase.data()?.contact.dateOfBirth
          ? moment(theCase.data()?.contact.dateOfBirth)
          : null,
      });
      dispatch({
        type: "SET_STREET1",
        payload: theCase.data()?.contact.address?.street1 || "",
      });
      dispatch({
        type: "SET_STREET2",
        payload: theCase.data()?.contact.address?.street2 || "",
      });
      dispatch({
        type: "SET_CITY",
        payload: theCase.data()?.contact.address?.city || "",
      });
      dispatch({
        type: "SET_STATE",
        payload: theCase.data()?.contact.address?.state || "",
      });
      dispatch({
        type: "SET_ZIP",
        payload: theCase.data()?.contact.address?.zip || "",
      });
    }
  }, [theCase, editCaseOpen]);

  const handleCancel = () => {
    setEditCaseOpen(false);
    setError("");
  };

  const handleSave = async () => {
    if (formState.terminationDate) {
      if (!userData || !theCase) return;

      if (formState.lastDay?.isAfter(formState.terminationDate)) {
        setError("Last Day of Work cannot be after Termination Date");
        return;
      }

      if (!validateEmailFormat(formState.email)) {
        setError("Please enter a valid email address");
        return;
      }

      if (!validateZipCode(formState.zip)) {
        setError("Invalid Zip Code format");
        return;
      }

      if (invalidTerminationDate || invalidLastDate) return;
      const changes: CaseActivityChange[] = [];

      const formatDate = (date: Moment | null) =>
        date ? date.format("MM/DD/YYYY") : null;

      addActivityChange(
        changes,
        "Termination Date",
        theCase.data()?.terminationDate,
        formatDate(formState.terminationDate)
      );
      addActivityChange(
        changes,
        "Last Day",
        theCase.data()?.lastDay,
        formatDate(formState.lastDay)
      );
      addActivityChange(
        changes,
        "Street Address 1",
        theCase.data()?.employee?.home?.street1,
        formState.street1
      );
      addActivityChange(
        changes,
        "Street Address 2",
        theCase.data()?.employee?.home?.street2,
        formState.street2
      );
      addActivityChange(
        changes,
        "City",
        theCase.data()?.employee?.home?.city,
        formState.city
      );
      addActivityChange(
        changes,
        "State",
        theCase.data()?.employee?.home?.state,
        formState.state
      );
      addActivityChange(
        changes,
        "Zip Code",
        theCase.data()?.employee?.home?.zip,
        formState.zip
      );
      addActivityChange(
        changes,
        "Date of Birth",
        theCase.data()?.employee?.dateOfBirth,
        formatDate(formState.dateOfBirth)
      );
      addActivityChange(
        changes,
        "Email",
        theCase.data()?.employee?.email,
        formState.email
      );
      addActivityChange(
        changes,
        "Phone",
        theCase.data()?.employee?.phone,
        formState.phone
      );
      addActivityChange(
        changes,
        "Termination Type",
        theCase.data()?.terminationType,
        formState.terminationType
      );
      addActivityChange(
        changes,
        "Reason",
        theCase.data()?.reason,
        formState.reason
      );

      const contactData = {
        lastName: theCase.data()?.employee?.name,
        email: formState.email,
        phone: formState.phone,
        address: {
          street1: formState.street1,
          street2: formState.street2,
          city: formState.city,
          state: formState.state,
          zip: formState.zip,
        },
        dateOfBirth: formState.dateOfBirth?.format("MM/DD/YYYY"),
      };

      // should not trigger klaviyo event yet, just make an edit to the doc
      await setDoc(
        doc(
          firestore,
          `companies/${theCase?.data()?.companyId}/cases/${caseId}`
        ).withConverter(CaseConverter),
        {
          contact: contactData,
          terminationDate: formState.terminationDate.format("MM/DD/YYYY"),
          lastDay: formState.lastDay?.format("MM/DD/YYYY"),
          terminationType: formState.terminationType,
          reason: formState.reason as CaseReason,
          employee: {
            email: formState.email,
            phone: formState.phone,
            dateOfBirth: formState.dateOfBirth?.format("MM/DD/YYYY"),
            terminationDate: formState.terminationDate.format("MM/DD/YYYY"),
            lastDay: formState.lastDay?.format("MM/DD/YYYY"),
            home: {
              zip: formState.zip,
            },
          },
        },
        { merge: true }
      );

      await addDoc<AdminCaseActivity>(
        collection(
          firestore,
          "companies",
          theCase?.data()?.companyId,
          "cases",
          theCase.id,
          "activity"
        ).withConverter(AdminCaseActivityConverter),
        {
          activity: CaseActivityTypes.CASE_UPDATED,
          type: "admin_action",
          userId: userData.id,
          caseId: theCase.id,
          companyId: theCase?.data()?.companyId,
          displayName: userData.data()?.name?.display || "Name Missing",
          message: "Case Updated",
          accessedBy: userData.id,
          changes,
          timestamp: moment(),
        }
      );
      setEditCaseOpen(false);
      notifications.success("Case updated successfully");
    }
  };

  return (
    <Dialog
      open={editCaseOpen}
      onClose={() => {
        setEditCaseOpen(false);
        setError("");
      }}
    >
      <Box
        sx={{
          width: 500,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h2">Edit Case</Typography>
        <FormControl fullWidth sx={{ display: "flex", gap: 2 }}>
          <DatePicker
            label="Last Day of Work"
            value={formState.lastDay}
            onChange={(date) => {
              dispatch({ type: "SET_LAST_DAY", payload: date });
              setError("");
            }}
            onError={(newError) => {
              setInvalidLastDate(
                newError
                  ? "Please provide a date with the following format MM/DD/YYYY"
                  : ""
              );
            }}
          ></DatePicker>
          {invalidLastDate && (
            <Typography color="error">{invalidLastDate}</Typography>
          )}
          <DatePicker
            label="Termination Date"
            value={formState.terminationDate}
            onChange={(date) => {
              dispatch({ type: "SET_TERMINATION_DATE", payload: date });
              setError("");
            }}
            onError={(newError) => {
              setInvalidTerminationDate(
                newError
                  ? "Please provide a date with the following format MM/DD/YYYY"
                  : ""
              );
            }}
          ></DatePicker>
          {invalidTerminationDate && (
            <Typography color="error">{invalidTerminationDate}</Typography>
          )}
          <TextField
            fullWidth
            label="Phone Number"
            value={formState.phone}
            onChange={(e) =>
              dispatch({ type: "SET_PHONE", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Contact Email"
            value={formState.email}
            onChange={(e) => {
              dispatch({ type: "SET_EMAIL", payload: e.target.value });
              setError("");
            }}
            margin="dense"
          />
          <FormControl>
            <FormLabel aria-labelledby="termination-type-label">
              Termination Type
            </FormLabel>
            <RadioGroup
              row
              aria-label="termination-type"
              name="termination-type"
              value={formState.terminationType}
              onChange={(e) => {
                if (
                  e.target.value === TerminationTypes.VOLUNTARY ||
                  e.target.value === TerminationTypes.INVOLUNTARY
                ) {
                  dispatch({
                    type: "SET_TERMINATION_TYPE",
                    payload: e.target.value,
                  });
                  dispatch({
                    type: "SET_REASON",
                    payload: ""
                  })
                }
              }}
            >
              <FormControlLabel
                value="VOLUNTARY"
                control={<Radio />}
                label="Voluntary"
              />
              <FormControlLabel
                value="INVOLUNTARY"
                control={<Radio />}
                label="Involuntary"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <InputLabel id="reason-for-exit">Reason for Exit</InputLabel>
            <Select
              value={formState.reason || ""}
              onChange={(e) => {
                dispatch({
                  type: "SET_REASON",
                  payload: e.target.value,
                });
              }}
              label={"Reason for Exit"}
            >
              {Object.values(CaseReason)
                .filter((key) =>
                  formState.terminationType === TerminationTypes.VOLUNTARY
                    ? VoluntaryReasons.includes(key)
                    : !VoluntaryReasons.includes(key)
                )
                .sort((a, b) =>
                  CaseReasonText(a).localeCompare(CaseReasonText(b))
                )
                .map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {CaseReasonText(key)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <DatePicker
            label="Date of Birth"
            value={formState.dateOfBirth}
            onChange={(date) =>
              dispatch({ type: "SET_DATE_OF_BIRTH", payload: date })
            }
          />
          <TextField
            fullWidth
            label="Street Address 1"
            value={formState.street1}
            onChange={(e) =>
              dispatch({ type: "SET_STREET1", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Street Address 2"
            value={formState.street2}
            onChange={(e) =>
              dispatch({ type: "SET_STREET2", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="City"
            value={formState.city}
            onChange={(e) =>
              dispatch({ type: "SET_CITY", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="State"
            value={formState.state}
            onChange={(e) =>
              dispatch({ type: "SET_STATE", payload: e.target.value })
            }
            margin="dense"
          />
          <TextField
            fullWidth
            label="Zip Code"
            value={formState.zip}
            onChange={(e) => {
              dispatch({ type: "SET_ZIP", payload: e.target.value });
              setError("");
            }}
            margin="dense"
          />
        </FormControl>
        {error && <Typography color="error">{error}</Typography>}
        <Button
          variant="contained"
          color="primary"
          disabled={!!error}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Box>
    </Dialog>
  );
};
export default AdminCaseEditDialog;
