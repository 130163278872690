import React, { useContext, useEffect, useState } from 'react';
import { FormattedNumber } from 'react-intl'
import { Lock } from "@mui/icons-material";
import { Interfaces } from 'services/apollo';
import {
  Box,
  Typography,
  Button,
  Paper,
  Grid,
  Divider,
  Rating,
  Dialog,
  Tooltip,
  Chip
} from "@mui/material";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useAuth } from 'contexts/User';
import { ModalContext } from 'contexts/Modal';
import SelectPlanModalQuotit from './SelectPlanModalQuotit';
import ViewDetailsModalQuotit from './ViewDetailsModalQuotit';
import { InsuranceType } from 'services/Interfaces';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useRemoteConfig } from 'contexts/RemoteConfig';

function PlanSummary(props: { plan: Interfaces.QuotitHealthPlanDetails, cobraPlan: any, title?: string, variant?: string, isMobile?: boolean, cobraCost?: number }) {
  const insuranceType = props.plan?.insuranceType === "HealthOnExchange" ? "On Exchange" : props.plan?.insuranceType === "HealthOffExchange" ? "Private Medical" : "Short Term Medical";
  const [cobraDiff, setCobraDiff] = useState<number>(0);
  const { user } = useAuth();
  const { modalDispatch } = useContext(ModalContext);
  const [selectPlanOpen, setSelectPlanOpen] = useState<boolean>(false);
  const [viewDetailsOpen, setViewDetailsOpen] = useState<boolean>(false);
  const { answers } = useAuth();
  const FF_IDEON = useRemoteConfig('ideon').asBoolean();
  const [federalSubsidyAmount, setFederalSubsidyAmount] = useState(0)
  const [imageError, setImageError] = useState(false);

  const deductibleBenefit = props.plan?.benefits.filter(benefit => benefit.enum === "ACA1MedicalDeductible" || benefit.enum === "ACA1MedicalDrugDeductible" || benefit.enum === "AnnualDeductible")[0];
  const deductible = FF_IDEON ? props.plan.deductible : deductibleBenefit?.inNetwork;

  const oopMaxBenefit = props.plan?.benefits.filter(benefit => benefit.enum === "ACA1EHBDrugOOPMax" || benefit.enum === "MaximumAnnualCopay")[0];
  const oopMax = FF_IDEON ? props.plan.moop : oopMaxBenefit?.inNetwork;

  if (!deductible || !oopMax) {
    console.log("deductible or oopMax not found", JSON.stringify(props.plan))
  }

  const handleImageError = () => {
    setImageError(true);
  };
  useEffect(() => {
    if (props.cobraCost) {
      const value = props.cobraCost - props.plan.rate
      console.log(value, "value");
      setCobraDiff(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cobraCost])

  useEffect(() => {
    const calculateSubsidyAmount = () => {
      const { rate = 0, rateSubsidized = rate as number } = props.plan || {};
      return FF_IDEON ? rate - rateSubsidized : answers?.data()?.insuranceDetails?.federalSubsidyAmount || 0;
    };

    const subsidyAmount = calculateSubsidyAmount();
    setFederalSubsidyAmount(subsidyAmount);

    if (props.cobraCost && subsidyAmount > 0) {
      setCobraDiff(props.cobraCost - (props.plan.rate - subsidyAmount));
    }
  }, [FF_IDEON, answers, props.cobraCost, props.plan, props.plan?.rate, props.plan?.rateSubsidized]);


  return (
    <>{props.plan &&
      <Paper
        sx={{
          borderRadius: 2,
          padding: 0,
          border: '1px solid #DEDEDE',
          background: '#fff',
          boxShadow: 'none',
          mt: 2,
          overflowX: 'auto',
        }}
      >
        <Grid container padding={props.isMobile ? 0 : 2}>
          <Grid item xs={12} sm={2} padding={0} >
            <Box
              sx={{
                gap: 1, overflowWrap: 'anywhere',
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRight: props.isMobile ? 'none' : '1px solid #ddd',
                borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                backgroundColor: props.isMobile ? '#F1F1F1' : 'white'
              }}
            >
              <Typography fontSize={14}>Name</Typography>
              <Tooltip title={props.plan.name} arrow>
                <Typography
                  sx={{
                    fontSize: 16,
                    color: '#000',
                    fontWeight: 600,
                    cursor: 'pointer',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {props.plan.name}
                </Typography>
              </Tooltip>
              <Box alignItems='center' display='flex'> <VerifiedUserIcon sx={{ fontSize: 'larger' }} /> <Typography fontWeight={600} fontSize={14}>Bronze</Typography></Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={1.7} padding={0}>
            <Box
              sx={{
                gap: 1, overflowWrap: 'anywhere',
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: props.isMobile ? 'row' : 'column',
                borderRight: !props.isMobile ? '1px solid #ddd' : 'none',
                borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                backgroundColor: props.isMobile ? '#fff' : 'white',
              }}
            >
              <Box width='100%' >
                <Typography fontSize={14}>Plan Type</Typography>
                <Typography fontSize={16} color="#000" fontWeight={600}>{props.plan.planType}</Typography>
              </Box>  <Box width='100%' >
                <Typography fontSize={14}>Insurance Type</Typography>
                <Typography fontSize={16} color="#000" fontWeight={600}>{insuranceType}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2.3} padding={0}>
            <Box
              sx={{
                gap: 1, overflowWrap: 'anywhere',
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: props.isMobile ? 'row' : 'column',
                borderRight: props.isMobile ? 'none' : '1px solid #ddd',
                borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
              }}
            >
              <Box >
                <Typography fontSize={14}>Monthly premium</Typography>
                <Typography fontSize={16} color="#000" fontWeight={600}>
                  {(federalSubsidyAmount > 0 && props.plan?.insuranceType === InsuranceType.federalExchangePlan) ? (
                    <Tooltip title={
                      <>
                        This premium amount includes an estimated premium tax credit amount of {' '}
                        <FormattedNumber
                          style={`currency`}
                          maximumFractionDigits={2}
                          currency="USD"
                          value={federalSubsidyAmount}
                        /> {' '}
                        per month, based on the income and household information you have provided. The exact amount may vary based on your final application and actual income.
                      </>
                    } arrow>
                      <span style={{ cursor: 'pointer', display: "flex", flexDirection: "column" }}>
                        <span style={{ textDecoration: 'line-through', marginRight: 5 }}>
                          <FormattedNumber value={props.plan.rate} style={`currency`} currency="USD" />
                        </span>
                        <span style={{ verticalAlign: 'middle', display: "flex", flexDirection: "row" }}>
                          <FormattedNumber value={props.plan.rate - federalSubsidyAmount} style={`currency`} currency="USD" />
                          <InfoOutlinedIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    <>
                      <FormattedNumber value={props.plan.rate} style={`currency`} currency="USD" />
                    </>
                  )}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'end', }}>
                {props.cobraCost && cobraDiff > 0 && props.cobraPlan?.cobra?.planComparison ?
                  (<Tooltip title={`SAVE $${Math.abs(cobraDiff).toFixed(2)} VS COBRA`} arrow>
                    <span style={{ cursor: 'pointer', }}>
                      <Chip
                        label={`SAVE $${Math.abs(cobraDiff).toFixed(2)} VS COBRA`}
                        sx={{
                          fontSize: '10px',
                        }}
                      />
                    </span>
                  </Tooltip>) : null}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} padding={0}>
            <Box
              sx={{
                gap: 1,
                overflowWrap: 'anywhere',
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                borderRight: !props.isMobile ? '1px solid #ddd' : 'none',
              }}
            >
              <Typography fontSize={14}>Insurer</Typography>
              {imageError ? (
                <Typography data-testid="carrier-name" fontSize={16} color="#000" fontWeight={600}>
                  {props.plan.carrier.name}
                </Typography>
              ) : (
                <Box>
                  <img
                    data-testid="carrier-logo"
                    src={props.plan.carrier.logo}
                    alt={props.plan.carrier.name}
                    onError={handleImageError}
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} padding={0}>
            <Box
              sx={{
                gap: 1, overflowWrap: 'anywhere',
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: props.isMobile ? 'row' : 'column',
                borderRight: !props.isMobile ? '1px solid #ddd' : 'none',
                borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
              }}
            >
              <Box width='100%' >
                <Typography fontSize={14}>Deductible</Typography>
                <Typography fontSize={16} color="#000" fontWeight={600}>{deductible}</Typography>
              </Box>  <Box width='100%' >
                <Typography fontSize={14}>Max Out of Pocket</Typography>
                <Typography fontSize={16} color="#000" fontWeight={600}>{oopMax}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2} padding={0}>
            <Box
              sx={{
                gap: 1, overflowWrap: 'anywhere',
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: props.isMobile ? 'row' : 'column',
                borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
              }}
            >
              <Box width='100%' >
                <Typography fontSize={14}>Score</Typography>
                <Typography fontSize={16} color="#000" fontWeight={600}>{props.plan.recommendation.internalScore}</Typography>
              </Box>
              <Box width='100%' >
                <Typography fontSize={14}>Star Rating </Typography>
                <Box width='100%' ><Rating sx={{ fontSize: { xs: "1.5rem", sm: "1rem", md: "1.50rem", lg: "1.5rem" } }} value={parseInt(props.plan.rating?.overall) || 0} readOnly /></Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ width: '100%' }} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            padding: '4px',
            backgroundColor: "#FCFBFB",
            justifyContent: "end",
          }}
        >
          <Button variant="outlined"
            onClick={() => { setViewDetailsOpen(true) }}
          >See Plan Details</Button>
          <Button variant="contained" onClick={() => {
            if (user?.isAnonymous) {
              modalDispatch({ type: "createAccount" });
            } else {
              setSelectPlanOpen(true)
            }
          }}>Select This Plan {user?.isAnonymous && <Lock sx={{ height: "16px", mt: "-4px" }} />}</Button>
        </Box>
      </Paper>
    }
      <Dialog
        open={selectPlanOpen}
        onClose={() => { setSelectPlanOpen(false) }}
        onBackdropClick={() => { setSelectPlanOpen(false) }}
        fullWidth
        data-testid="selectPlan"
        maxWidth="lg"
        sx={{
        }}
      >
        <SelectPlanModalQuotit plan={props.plan} close={() => { setSelectPlanOpen(false) }} />
      </Dialog>
      <Dialog
        open={viewDetailsOpen}
        onClose={() => { setViewDetailsOpen(false) }}
        onBackdropClick={() => { setViewDetailsOpen(false) }}
        fullWidth
        maxWidth="lg"
      >
        <ViewDetailsModalQuotit plan={props.plan} close={() => { setViewDetailsOpen(false) }} />
      </Dialog>
    </>
  )
}
export default PlanSummary