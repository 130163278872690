import { CarrierAppointmentsCSVInput } from "./interfaces";

export const headerMapping: { [key: string]: keyof CarrierAppointmentsCSVInput } = {
  "Last Name": "lastName",
  "First Name": "firstName",
  NPN: "NPN",
  "Home State": "homeState",
  "Producer Status": "producerStatus",
  State: "state",
  Carrier: "carrier",
  "NAIC Code": "NAICCode",
  LOA: "LOA",
  "Appointment Status": "appointmentStatus",
  "Appointment Effective Date": "appointmentEffectiveDate",
  "Appointment Expiration Date": "appointmentExpirationDate",
  "LOA Status": "LOAStatus",
  "Org Name": "OrgName",
};

export const mapCSVDataToCarrierAppointments = (csvRecords: string[][]) => {
  const headers = csvRecords[0];
  const content = csvRecords.slice(1);

  const mappedData = content.map((row: string[]) => {
    return headers.reduce((obj: Partial<CarrierAppointmentsCSVInput>, header: string, index: number) => {
      const mappedKey = headerMapping[header];
      if (mappedKey) {
        if (mappedKey === "NPN") {
          (obj as any)[mappedKey] = Number(row[index]);
        } else {
          (obj as any)[mappedKey] = row[index];
        }
      }
      return obj;
    }, {});
  }) as CarrierAppointmentsCSVInput[];

  return mappedData;
};
