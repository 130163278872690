import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  Chip,
  Dialog,
  Grid,
  Tooltip,
  Box,
} from "@mui/material";
// import SelectPlanModalQuotit from "components/HealthPlan/SelectPlanModalQuotit";
import ViewDetailsModalQuotit from "components/HealthPlan/ViewDetailsModalQuotit";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import {
  query,
  collection,
  where,
  limit,
  orderBy,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { FormattedNumber } from "react-intl";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useRemoteConfig } from "contexts/RemoteConfig";

interface PremiumTier {
  employeeContribution: number;
  employerContribution: number;
  totalPremium: number;
}

interface FourTierPremium {
  employeeOnly: PremiumTier;
  employeePlusChildren: PremiumTier;
  employeePlusSpouse: PremiumTier;
  family: PremiumTier;
}

interface BenefitPlan {
  id: string;
  name: string;
  carrier: string;
  planNetworkType: string;
  individualDeductible: number;
  familyDeductible: number;
  individualOutOfPocketMaximum: number;
  familyOutOfPocketMaximum: number;
  fourTierPremium: FourTierPremium;
}

// interface QuotitBenefit {
//   enum: string;
//   inNetwork: string;
// }

// interface QuotitCarrier {
//   name: string;
//   largeLogo: string;
// }

// interface QuotitPlan {
//   id: string;
//   name: string;
//   carrier: QuotitCarrier;
//   planType: string;
//   rate: number;
//   benefits: QuotitBenefit[];
// }

// Add this interface for the plan details
interface SelectedPlanDetails {
  id: string;
  name: string;
  carrier: string;
  planType: string;
  rate: number;
  deductible: string;
  outOfPocketMax: string;
}

const PlanOptionsJamieAI = ({
  effectiveDate,
  speed,
  finished,
  autoScroll,
  cobraEstimate,
  currentPlan,
  conversationId,
  sendPrompt,
}: {
  effectiveDate: string;
  speed: number;
  finished?: Function;
  autoScroll?: Function;
  cobraEstimate: number;
  currentPlan?: BenefitPlan;
  conversationId?: string;
  sendPrompt: (message: string) => void;
}) => {
  const { user, answers } = useAuth();
  const { firestore } = useFirebase();
  const [openViewPlan, setOpenViewPlan] = useState(false);
  const [, setOpenChoosePlan] = useState(false);
  const [, setPlanToChoose] = useState<any>(null);
  const [planToView, setPlanToView] = useState<any>(null);
  const [planOptions, setPlanOptions] = useState<Array<any>>([]);

  // Get selected benefit plan
  const [selectedBenefitPlan, setSelectedBenefitPlan] =
    useState<BenefitPlan | null>(null);

  const FF_ideon = useRemoteConfig("ideon").asBoolean();

  useEffect(() => {
    const fetchSelectedPlan = async () => {
      if (!user?.uid || !firestore) return;

      try {
        const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
        const settingsDoc = await getDoc(settingsRef);

        if (settingsDoc.exists() && settingsDoc.data().selectedPlan) {
          const planId = settingsDoc.data().selectedPlan;
          const planRef = doc(
            firestore,
            `companies/when/benefitPlans/${planId}`,
          );
          const planDoc = await getDoc(planRef);

          if (planDoc.exists()) {
            setSelectedBenefitPlan({
              id: planDoc.id,
              ...planDoc.data(),
            } as BenefitPlan);
          }
        }
      } catch (error) {
        console.error("Error fetching selected plan:", error);
      }
    };

    fetchSelectedPlan();
  }, [user?.uid, firestore]);

  // Determine the premium tier based on household members
  const determinePremiumTier = (members: any): keyof FourTierPremium => {
    if (!members) return "employeeOnly";

    const memberCount = Object.keys(members).length;
    const hasSpouse = Object.values(members).some(
      (member: any) => member.relationship === "spouse",
    );
    const hasChildren = Object.values(members).some(
      (member: any) => member.relationship === "child",
    );

    if (memberCount === 1) return "employeeOnly";
    if (hasSpouse && hasChildren) return "family";
    if (hasSpouse) return "employeePlusSpouse";
    if (hasChildren) return "employeePlusChildren";
    return "employeeOnly";
  };

  const household = answers?.get("insuranceDetails.household.members");
  const premiumTier = determinePremiumTier(household);

  const [plans] = useCollection(
    user?.uid
      ? query(
          collection(
            firestore,
            `users/${user.uid}/effectiveDate/${effectiveDate}/plans`,
          ),
          orderBy("insuranceType"),
          orderBy("recommendation.internalScore", "desc"),
          where("insuranceType", "!=", "ShortTerm"),
          limit(3),
        )
      : null,
  );

  const [shortTermPlan] = useCollection(
    user?.uid
      ? query(
          collection(
            firestore,
            `users/${user.uid}/effectiveDate/${effectiveDate}/plans`,
          ),
          orderBy("insuranceType"),
          orderBy("recommendation.internalScore", "desc"),
          where("insuranceType", "==", "ShortTerm"),
          limit(1),
        )
      : null,
  );

  useEffect(() => {
    if (!plans?.docs && !shortTermPlan?.docs) return;

    const planOptions: any[] = [];
    let InsurancePlans: any[] = [];
    let shortTermInsurancePlans: any[] = [];

    if (plans?.docs) {
      InsurancePlans = plans.docs.map((plan) => plan.data());
    }

    if (shortTermPlan?.docs) {
      shortTermInsurancePlans = shortTermPlan.docs.map((plan) => plan.data());
    }

    if (InsurancePlans.length >= 2) {
      if (shortTermInsurancePlans.length > 0) {
        planOptions.push(InsurancePlans[0]);
        planOptions.push(InsurancePlans[1]);
        planOptions.push(shortTermInsurancePlans[0]);
      } else if (InsurancePlans.length >= 3) {
        planOptions.push(InsurancePlans[0]);
        planOptions.push(InsurancePlans[1]);
        planOptions.push(InsurancePlans[2]);
      }
    }

    setPlanOptions(planOptions);
  }, [plans, shortTermPlan]);

  const federalSubsidyAmount =
    answers?.data()?.insuranceDetails?.federalSubsidyAmount;

  function getSubsidyAmount(originalPremium: number, subsidyAmount: number) {
    if (subsidyAmount < originalPremium) {
      return originalPremium - subsidyAmount;
    }
  }

  const saveRecommendedPlansToConvo = useCallback(async (recommendedPlans: any[]) => {
    if (!user?.uid || !conversationId) return;
  
    try {
      const convoRef = doc(
        firestore,
        `users/${user.uid}/convos/${conversationId}`,
      );
      const convoSnapshot = await getDoc(convoRef);
  
      if (convoSnapshot.exists()) {
        const convoData = convoSnapshot.data();
        const messages = convoData.messages || [];
  
        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
  
          // Format plans data with correct value extraction
          const formattedPlans = recommendedPlans.map((plan) => {
            let deductible = "$0";
            let outOfPocketMax = "$0";
  
            if (plan.benefits) {
              const deductibleBenefit = plan.benefits.find(
                (benefit: any) => benefit.enum === "ACA1MedicalDrugDeductible",
              );
              const outOfPocketBenefit = plan.benefits.find(
                (benefit: any) => benefit.enum === "ACA1EHBDrugOOPMax",
              );
  
              if (deductibleBenefit) deductible = deductibleBenefit.inNetwork;
              if (outOfPocketBenefit)
                outOfPocketMax = outOfPocketBenefit.inNetwork;
            }
  
            return {
              id: plan.id,
              name: plan.name,
              rate: plan.rate || 0,
              deductible: deductible,
              outOfPocketMax: outOfPocketMax,
            };
          });
  
          if (!lastMessage.data) {
            lastMessage.data = {};
          }
  
          lastMessage.data.recommendedPlans = formattedPlans;
  
          await updateDoc(convoRef, {
            messages: messages,
          });
        }
      }
    } catch (error) {
      console.error("Error saving recommended plans:", error);
    }
  }, [user?.uid, conversationId, firestore]); 
  
  useEffect(() => {
    if (planOptions.length > 0) {
      saveRecommendedPlansToConvo(planOptions);
    }
  }, [planOptions, saveRecommendedPlansToConvo]);

  const updateSelectedPlanDetails = async (plan: any) => {
    if (!user?.uid || !firestore) return;

    try {
      const settingsRef = doc(firestore, `users/${user.uid}/settings/chat`);
      
      // Extract plan details
      let deductible = "$0";
      let outOfPocketMax = "$0";

      if (plan.benefits) {
        const deductibleBenefit = plan.benefits.find(
          (benefit: any) => benefit.enum === "ACA1MedicalDrugDeductible"
        );
        const outOfPocketBenefit = plan.benefits.find(
          (benefit: any) => benefit.enum === "ACA1EHBDrugOOPMax"
        );

        if (deductibleBenefit) deductible = deductibleBenefit.inNetwork;
        if (outOfPocketBenefit) outOfPocketMax = outOfPocketBenefit.inNetwork;
      }

      const planDetails: SelectedPlanDetails = {
        id: plan.id,
        name: plan.name,
        carrier: plan.carrier.name,
        planType: plan.planType,
        rate: plan.rate || 0,
        deductible,
        outOfPocketMax,
      };

      await updateDoc(settingsRef, {
        selectedPlan: plan.id,
        selectedPlanDetails: planDetails,
      });

      sendPrompt("I have selected a plan and I would like to enroll.");

    } catch (error) {
      console.error("Error updating selected plan details:", error);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={0.25}
        sx={{
          padding: 1,
          backgroundColor: "#F1F1F1",
          borderRadius: "17px 17px 17px 2px",
        }}
      >
        {selectedBenefitPlan && (
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ borderRadius: "10px", overflow: "hidden" }}
          >
            <Box
              sx={{
                p: 2,
                backgroundColor: "#F2EDE7",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                height: { xs: 110, sm: 150 },
                marginTop: { xs: 1, sm: 0 },
              }}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  height: "100%",
                  maxHeight: "4em",
                  fontSize: { xs: "0.9rem", sm: "1.2rem" },
                }}
              >
                {selectedBenefitPlan.name}
              </Typography>
              <Typography
                variant="h5"
                sx={{ fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
              >
                {selectedBenefitPlan.planNetworkType}
              </Typography>
            </Box>

            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: 120,
                    borderBottom: "2px solid #F2EDE7",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                  >
                    Monthly Premium
                  </Typography>
                  <Typography fontSize={16} color="#000" fontWeight={600}>
                    <FormattedNumber
                      value={
                          selectedBenefitPlan.fourTierPremium[premiumTier]
                          ?.totalPremium || 0
                        }
                        /* eslint-disable-next-line react/style-prop-object */
                        style="currency"
                      currency="USD"
                    />
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Your Contribution:{" "}
                    <FormattedNumber
                      value={
                        selectedBenefitPlan.fourTierPremium[premiumTier]
                          ?.employeeContribution || 0
                      }
                      /* eslint-disable-next-line react/style-prop-object */
                      style="currency"
                      currency="USD"
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: 120,
                    borderBottom: "2px solid #F2EDE7",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                  >
                    Insurer
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {selectedBenefitPlan.carrier}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 1,
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: 80,
                    borderBottom: "2px solid #F2EDE7",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                  >
                    Deductible
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <FormattedNumber
                      value={selectedBenefitPlan.individualDeductible}
                      /* eslint-disable-next-line react/style-prop-object */
                      style="currency"
                      currency="USD"
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 1,
                    backgroundColor: "#FFFFFF",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: 80,
                    borderBottom: "2px solid #F2EDE7",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                  >
                    Max Out of Pocket
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <FormattedNumber
                      value={selectedBenefitPlan.individualOutOfPocketMaximum}
                      /* eslint-disable-next-line react/style-prop-object */
                      style="currency"
                      currency="USD"
                    />
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                p: 1,
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Button variant="contained" color="primary" fullWidth disabled>
                Selected Plan
              </Button>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() => {
                  setOpenViewPlan(true);
                  setPlanToView(selectedBenefitPlan);
                }}
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                View Plan Details
              </Button>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() =>
                  sendPrompt(`Explain this plan. Plan ID: ${selectedBenefitPlan.id}`)
                }
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Explain This Plan
              </Button>
            </Box>
          </Grid>
        )}

          {planOptions.map((plan) => {
            const save = cobraEstimate - (plan?.rate || 0);
            let outOfPocketMax = plan.outOfPocketMax;
            let deductible = plan.deductible;

            // Check if it's an Ideon plan
            if (FF_ideon) {
              outOfPocketMax = plan.moop;
              deductible = plan.deductible;
            } else if (plan?.benefits) {
              // Existing logic for non-Ideon plans
              const outOfPocketBenefit = plan.benefits.find(
                (benefit: any) => benefit.enum === "ACA1EHBDrugOOPMax",
              );
              const deductibleBenefit = plan.benefits.find(
                (benefit: any) => benefit.enum === "ACA1MedicalDrugDeductible",
              );

              if (outOfPocketBenefit)
                outOfPocketMax = outOfPocketBenefit.inNetwork;
              if (deductibleBenefit) 
                deductible = deductibleBenefit.inNetwork;
            }

          return (
            <Grid
              item
              xs={12}
              sm={4}
              key={plan.id}
              sx={{ borderRadius: "10px", overflow: "hidden" }}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "#F2EDE7",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: { xs: 110, sm: 150 },
                  cursor: "pointer",
                  marginTop: { xs: 1, sm: 0 },
                }}
              >
                <Tooltip
                  title={
                    <Box sx={{ backgroundColor: "#FFFFFF", border: "none" }}>
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        {plan.name}
                      </Typography>
                    </Box>
                  }
                  arrow
                  placement="top"
                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        backgroundColor: "#FFFFFF",
                        border: "none",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      },
                      "& .MuiTooltip-arrow": {
                        color: "#FFFFFF",
                      },
                    },
                  }}
                >
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    dangerouslySetInnerHTML={{ __html: plan.name }}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      height: "100%",
                      maxHeight: "4em",
                      fontSize: { xs: "0.9rem", sm: "1.2rem" },
                    }}
                  />
                </Tooltip>
                <Typography
                  variant="h5"
                  sx={{ fontSize: { xs: "0.9rem", sm: "1.2rem" } }}
                >
                  {plan.planType}
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      height: 120,
                      borderBottom: "2px solid #F2EDE7",
                      borderRight: { xs: "1px solid #F2EDE7", sm: "none" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                    >
                      Monthly Premium
                    </Typography>
                    <Typography fontSize={16} color="#000" fontWeight={600}>
                      {federalSubsidyAmount &&
                      plan.rate > federalSubsidyAmount ? (
                        <Tooltip
                          sx={{ marginLeft: "10px" }}
                          title={`This premium amount includes an estimated premium tax credit amount of $${federalSubsidyAmount} per month, based on the income and household information you have provided. The exact amount may vary based on your final application and actual income.`}
                          arrow
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                textDecoration: "line-through",
                                marginRight: 5,
                              }}
                            >
                              <FormattedNumber
                                value={plan.rate ? plan.rate : 0}
                                /* eslint-disable-next-line react/style-prop-object */
                                style="currency"
                                currency="USD"
                              />
                            </span>
                            <span
                              style={{
                                verticalAlign: "middle",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <FormattedNumber
                                value={
                                  getSubsidyAmount(
                                    plan.rate,
                                    federalSubsidyAmount,
                                  ) || 0
                                }
                                /* eslint-disable-next-line react/style-prop-object */
                                style="currency"
                                currency="USD"
                              />
                              <InfoOutlinedIcon
                                fontSize="small"
                                style={{ verticalAlign: "middle" }}
                              />
                            </span>
                          </span>
                        </Tooltip>
                      ) : (
                        <>
                          <FormattedNumber
                            value={plan.rate ? plan.rate : 0}
                            /* eslint-disable-next-line react/style-prop-object */
                            style="currency"
                            currency="USD"
                          />
                        </>
                      )}
                    </Typography>
                    {save > 0 && (
                      <Tooltip
                        title={
                          <Box
                            sx={{ backgroundColor: "#F2EDE7", border: "none" }}
                          >
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              SAVE ${save.toFixed(2)} VS COBRA
                            </Typography>
                          </Box>
                        }
                        arrow
                        placement="bottom"
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "#F2EDE7",
                              border: "none",
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                            },
                            "& .MuiTooltip-arrow": {
                              color: "#F2EDE7",
                            },
                          },
                        }}
                      >
                        <Chip
                          label={`SAVE $${save.toFixed(2)} VS COBRA`}
                          sx={{
                            fontSize: "0.8rem",
                            backgroundColor: "#AFC3FF",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      height: 120,
                      borderBottom: "2px solid #F2EDE7",
                      borderLeft: { xs: "1px solid #F2EDE7", sm: "none" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                    >
                      Insurer
                    </Typography>
                    <img
                      src={plan.carrier.largeLogo}
                      alt={plan.carrier.name}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={12}>
                  <Box
                    sx={{
                      p: 1,
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      height: 80,
                      borderBottom: "2px solid #F2EDE7",
                      borderRight: { xs: "1px solid #F2EDE7", sm: "none" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                    >
                      Deductible
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {deductible}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={12}>
                  <Box
                    sx={{
                      p: 1,
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      height: 80,
                      borderBottom: "2px solid #F2EDE7",
                      borderLeft: { xs: "1px solid #F2EDE7", sm: "none" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: { xs: "0.7rem", sm: "1.1rem" } }}
                    >
                      Max Out of Pocket
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {outOfPocketMax}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  p: 1,
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Button
                  data-testid="choose-this-plan"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setOpenChoosePlan(true);
                    setPlanToChoose(plan);
                    updateSelectedPlanDetails(plan);
                  }}
                >
                  Choose This Plan
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  data-testid="view-plan-details"
                  fullWidth
                  onClick={() => {
                    setOpenViewPlan(true);
                    setPlanToView(plan);
                  }}
                  sx={{
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  View Plan Details
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  fullWidth
                  onClick={() => sendPrompt(`Explain this plan. Plan ID: ${plan.id}`)}
                  sx={{
                    textDecoration: "underline",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Explain This Plan
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {planToView && openViewPlan && (
        <Dialog
          open={openViewPlan}
          onClose={() => {
            setOpenViewPlan(false);
            setPlanToView(null);
          }}
          fullWidth
          maxWidth="lg"
        >
          <ViewDetailsModalQuotit
            plan={planToView}
            close={() => {
              setOpenViewPlan(false);
              setPlanToView(null);
            }}
          />
        </Dialog>
      )}

      {/* {planToChoose && openChoosePlan && (
        <Dialog
          open={openChoosePlan}
          onClose={() => { setOpenChoosePlan(false); setPlanToChoose(null) }}
          fullWidth
          maxWidth="lg"
        >
          <SelectPlanModalQuotit plan={planToChoose} close={() => { setOpenChoosePlan(false); setPlanToChoose(null) }} />
        </Dialog>
      )} */}
      {/* <span ref={scrollSpan}></span> */}
    </>
  );
};

export default PlanOptionsJamieAI;
