import { DocumentData, SnapshotOptions, FirestoreDataConverter, QueryDocumentSnapshot } from "firebase/firestore";
import moment from "moment";
import { Employee } from "services/Interfaces";


export const EmployeeConverter: FirestoreDataConverter<Employee> = {
  toFirestore(employee: Employee): DocumentData {
    return {
      ...employee,
      startDate: employee.startDate?.toDate(),
      dateOfBirth: employee.dateOfBirth,
      terminationDate: employee.terminationDate?.toDate(),
      termination: {
        ...employee.termination,
        terminationDate: employee.termination?.terminationDate?.toDate()
      }
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Employee {
    const data = snapshot.data(options);
    return {
      uid: data.uid,
      name: data.name,
      firstName: data.firstName,
      lastName: data.lastName,
      companyId: data.companyId,
      flsa: data.flsa,
      manager: data.manager,
      team: data.team,
      department: data.department,
      work: data.work,
      startDate: data.startDate ? moment.utc(data.startDate.toDate()) : moment(),
      dateOfBirth: data.dateOfBirth.toDate ? moment.utc(data.dateOfBirth.toDate()).format("MM/DD/YYYY") : data.dateOfBirth,
      terminationDate: data?.terminationDate ? (data.terminationDate.toDate ? moment.utc(data.terminationDate.toDate()) : undefined) : undefined,
      termination: {
        ...data.termination,
        terminationDate: data?.termination && data?.termination?.terminationDate ? moment(data?.termination?.terminationDate.toDate()) : null
      },
      email: data.email,
      cobra: data.cobra,
      payFrequency: data.payFrequency,
      payPeriod: data.payPeriod,
      payRate: data.payRate,
      personalEmail: data.personalEmail,
      workEmail: data.workEmail,
      phone: data.phone,
      status: data.status,
      home: data.home,
      gender: data.gender,
      ethnicity: data.ethnicity,
      maritalStatus: data.maritalStatus,
      salary: data.salary,
      payCurrency: data.payCurrency,
      employeeType: data.employeeType,
      employeeNumber: data.employeeNumber,
      benefit: data.benefit,
      exitCode: data.exitCode,
      whenBenefit: data.whenBenefit,
      benefitTier: data.benefitTier,
      title: data.title,
      hris: data.hris,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};